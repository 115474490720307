import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from 'page/news/NewsPage.module.css';
import classNames from 'classnames';
import HeaderSubMenu from 'page/layout/menu/HeaderSubMenu';
import importantNewsIcon from './importantNewsIcon.png';
import { useDispatch, useSelector } from 'react-redux';
import { SEARCH } from 'redux/news/actions';
import { actionsSelector } from 'redux/app/selectors';
import Preloader from 'components/preloader/Preloader';
import { NEWS_CATEGORIES } from 'constants/newsCategories';
import { formatDate, parseISO } from 'utils/timeUtils';
import { AppTypes } from 'types/AppTypes';
import { NewsTypes } from 'types/NewsTypes';
import Notification from 'components/notification/Notification';
import { useTranslation } from 'react-i18next';
import searchStringIcon from 'themes/icons/search.svg';
import Icon from 'components/icon/Icon';
import IsAvailable from 'components/access/IsAvailable';
import { ThemeContext } from 'themes/Themes';
const queryString = require('query-string');

const NewsPage = () => {
    const { newsByCategory } = useSelector((state: AppTypes.State) => state.news);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [searchString, setSearchString] = useState('');
    const { nameCompany } = useContext(ThemeContext);
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const location: any = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, searchString]);

    const search = () => {
        dispatch(SEARCH.base({ category: queryString.parse(location.search).category, searchString }));
    };

    // @ts-ignore
    const category = NEWS_CATEGORIES[`${queryString.parse(location.search).category}`];

    return (
        <div className={styles.container}>
            <IsAvailable options={[{ news: true }]}>
                <HeaderSubMenu />
                <div className={styles.content}>
                    <div className={styles.pageHead}>
                        <h1 className={styles.title}>{category?.localeKey ? t(`${category?.localeKey}`, { nameCompany }) : category?.name}</h1>
                        <div className={styles.searchPanel}>
                            <input
                                value={searchString}
                                type="search"
                                placeholder={t(`Common.Search`)}
                                onChange={(event) => setSearchString(event.target.value)}
                            />
                            <button disabled={searchString.length < 1} onClick={() => search()}>
                                <Icon setDefaultStyles={false} icon={`${searchStringIcon}#search`} className={styles.searchStringIcon} />
                            </button>
                        </div>
                    </div>
                    {actions[SEARCH.BASE]?.loading ? (
                        <Preloader />
                    ) : (
                        newsByCategory.length >= 0 && (
                            <Fragment>
                                {Array.isArray(selectedOfficeOrGroup?.offices) && <Notification>{t(`NewsPage.officeNotice`)}</Notification>}
                                <ul className={styles.newsList}>
                                    {newsByCategory.map((news: NewsTypes.News) => (
                                        <li key={news.id}>
                                            {news.important && <img alt="" className={styles.importantNewsIcon} src={importantNewsIcon} />}
                                            <Link
                                                className={classNames([styles.newsListItem, !news.viewed ? styles.new : null])}
                                                to={`/news/${news.id}?category=${news.category}`}
                                            >
                                                {news.title}
                                            </Link>
                                            <span className={styles.newsListItemDate}>
                                                {news.startDate && formatDate(parseISO(news.startDate), 'ru', 'dd MMMM yyyy')}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </Fragment>
                        )
                    )}
                    {newsByCategory.length < 1 && !actions[SEARCH.BASE]?.loading && <p>{t(`NewsPage.newsNotFound`)}</p>}
                </div>
            </IsAvailable>
        </div>
    );
};

export default React.memo(NewsPage);
