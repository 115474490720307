import { LKE_KK } from 'themes/invitro/IsInvitro';

export const NEWS_CATEGORIES = {
    INVITRO_NEWS: {
        name: 'Новости {{nameCompany}}',
        path: 'INVITRO_NEWS',
        localeKey: 'Common.news'
    },
    NEW_RESEARCH: {
        name: 'Новые исследования',
        path: 'NEW_RESEARCH'
    },
    TECHNOLOGICAL_INFORMATION: {
        name: 'Технологическая информация',
        path: 'TECHNOLOGICAL_INFORMATION'
    },
    BLANKS: {
        name: 'Бланки',
        path: 'BLANKS'
    },
    INSTRUCTIONS_DOCTOR: {
        name: 'Инструкции врача',
        path: 'INSTRUCTIONS_DOCTOR'
    },
    INSTRUCTIONS_NURSE: {
        name: 'Инструкции медсестры',
        path: 'INSTRUCTIONS_NURSE',
        siteStyle: LKE_KK
    }
};
